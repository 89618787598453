// Libraries
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const Loading = props => {

	// Props
	const { message, style } = props;


	return (
		<div className="Loading" style={style}>

			<div className="Loading__spinner"/>

			{ message &&
				<div className="Loading__message">{message}</div>
			}

		</div>
	);
}


// Prop Types
Loading.propTypes = {
	message: PropTypes.string.isRequired,
	style: PropTypes.object.isRequired
}


// Default Props
Loading.defaultProps = {
	message: '',
	style: {}
}


export default Loading;
// Libraries
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';


// Assets
import './styles.css';


const Breadcrumb = props => {

	// Props
	const { items } = props;


	return (
		<div className="Breadcrumb">
			<ul className="Breadcrumb__list">
			{items.map((item) => {

				return (
					<li key={item.url} className="Breadcrumb__list__item">
						<Link to={item.url} className="Breadcrumb__list__item__link">
							{item.label}
						</Link>
					</li>
				);

			})}
			</ul>
		</div>
	);
}

Breadcrumb.propTypes = {
	items: PropTypes.array
}

export default Breadcrumb;

